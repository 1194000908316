import React, { Fragment } from 'react';
import { Link, graphql } from 'gatsby';
import { Layout } from '../components/index';
import reactHtmlParser from 'react-html-parser';

export default ({ data: { markdownRemark: { frontmatter: { date, title }, html } } }) => {
    return (
        <Layout pageTitle={title} showHeader activeTab={'news'}>
            <div id="wrapper">
                <section id="main" className="wrapper">
                    <div className="inner">
                        <h2 className="major">{title}</h2>
                        {date ? <p>{date}</p> : <Fragment />}
                        {reactHtmlParser(html)}
                        <Link to={"/"} className="button">Back to Home</Link>
                    </div>
                </section>
            </div>
        </Layout>
    );
}

export const articleQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
      }
    }
  }
`